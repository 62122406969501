/* eslint-disable max-len */
import React from 'react'

import PortfolioPizza from '../../../../components/Recharts/Pizza'
import * as S from './style'
import { IPortifolio } from '../../types'

interface IPortifolioProps {
  data: {
    text: string;
    charts: {
      title: string;
      subtitle: string;
      data: {
        name: string;
        percentage: number;
        key: string;
      }[];
      key: string;
    }[];
    key: string;
    };
  anchor: string;
}

const Portfolio = ({ anchor, data }: IPortifolioProps) => {
  return (
    <S.SectionWrapper id={anchor} className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Portfólio
            </h2>
          </div>
          <div className='col-12 mb-4'>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 f-inter text-gray-750 fw-400 m-0' dangerouslySetInnerHTML={{ __html: data.text }} />
          </div>
          {data.charts.map((chart: IPortifolio) => (
            <div className='col-12 col-xl-6 mb-4 mb-xl -0' key={chart.key}>
              <PortfolioPizza title={chart.title} description={chart.subtitle} graphicInfo={chart.data} />
            </div>
          ))}
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Portfolio
