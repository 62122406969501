/* eslint-disable max-len */
import React from 'react'

import * as S from './style'

interface IMensagemAosInvestidoresProps {
  data: {
    messageToInvestors: string;
  };
  anchor: string;
}

const MensagemAosInvestidores = ({ anchor, data }: IMensagemAosInvestidoresProps) => {
  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='fs-16 lh-20 fs-md-20 lh-md-26 fw-400 text-gray-750 f-sora mt-2 mb-4' dangerouslySetInnerHTML={{ __html: data.messageToInvestors }} />
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default MensagemAosInvestidores
