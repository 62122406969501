/* eslint-disable max-len */
import React from 'react'

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import * as S from './style'
import { IChart, IChartArray, IChartData, IChartKeyList, ILineData, IListedFoundsSections } from '../../types'
import slugify from 'slugify'
import { formatToDayMonthYearDate, getMonthAndYear, haveAlphabeticChar, removeDuplicatedObjectFromChartArray, removeUndefinedNameFromChartArray, returnChartKeysList } from '../../../../hooks/utility'
import { rechartsStrokeColors } from '../../../../styles/colors'

interface IChartLinesData {
  lines: ILineData[];
  subtitle: string;
  key: string;
};

interface IPrincipaisIndicadores extends IListedFoundsSections {
  chartData: IChartLinesData;
}

const PrincipaisIndicadores = ({ anchor, chartData }: IPrincipaisIndicadores) => {
  const [ chart, setChart ] = React.useState<IChart[] | null>(null)
  const [ chartKeyList, setChartKeyList ] = React.useState<IChartKeyList[]>()

  function removeDuplicates (chartData: IChartLinesData) {
    chartData.lines.forEach((line: ILineData) => {
      line.data = line.data.filter((data: IChartData, index: number, arr: IChartData[]) => {
        return arr.findIndex((d: IChartData) => d.xAxis === data.xAxis) === index
      })
    })

    return chartData
  }

  const returnChartData = () => {
    const chartDataRemovingDuplicated = removeDuplicates(chartData)

    const chart = chartDataRemovingDuplicated.lines.reduce((accumulator: IChart[], current: ILineData) => {
      current.data.map((data: IChartArray) => {
        accumulator.push({
          name: data.xAxis,
        })
      })
      return accumulator
    }, [])

    const uniqueArray = removeDuplicatedObjectFromChartArray(chart)

    chartDataRemovingDuplicated.lines.forEach((line: ILineData) => {
      line.data.forEach((lineData: IChartArray, indexData: number) => {
        if (uniqueArray[indexData] && uniqueArray[indexData].name) {
          if (lineData.xAxis === uniqueArray[indexData].name) {
            uniqueArray.splice(indexData, 1, {
              ...uniqueArray[indexData],
              [line.name]: parseFloat(lineData.yAxis),
            })
          }
        }
      })
    })

    const keysList: IChartKeyList[] = returnChartKeysList(uniqueArray)
    setChartKeyList(keysList)

    const filteredArray = removeUndefinedNameFromChartArray(uniqueArray)

    formatToDayMonthYearDate(filteredArray)

    return filteredArray
  }

  React.useEffect(() => {
    setChart(returnChartData())
  }, [])

  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Principais Indicadores
            </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 fs-lg-20 lh-lg-25 text-gray-750 f-sora fw-600 mb-4'>
              {chartData.subtitle}
            </p>
          </div>
          <div className='col-12'>
            {chart && chartKeyList &&
              <ResponsiveContainer width='100%' height={300}>
                <LineChart
                  data={chart}
                  margin={{
                    top: 20,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' tickFormatter={(value: string) => haveAlphabeticChar(value) ? getMonthAndYear(value) : value } />
                  <YAxis />
                  <Tooltip formatter={(value: number) => `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`} />
                  <Legend verticalAlign='top' align='left' iconType='rect' height={90} />
                  {chartKeyList.map((chart: IChartKeyList, index: number) => (
                    <Line key={slugify(String(chart.lineDataKey))} dot={false} type='monotone' dataKey={chart.lineDataKey} strokeWidth={3} stroke={rechartsStrokeColors[index]} />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            }
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default PrincipaisIndicadores
