import styled from 'styled-components'
import { gray, white } from '../../../../styles/colors'
import breakpoints from '../../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  background-color: ${gray[400]};

  .documents-listing {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
`

export const Card = styled.section`
  background-color: ${white};
  border: 1px solid ${gray[550]};
  width: 150px;
  height: auto;
  min-height: 62px;
  padding: 12px;
  border-radius: 8px;

  @media (min-width: ${breakpoints.md}) {
    width: 126px;
    min-height: 70px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 174px;
    min-height: 60px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 212px;
    min-height: 76px;
  }
`
