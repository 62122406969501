/* eslint-disable max-len */
import React from 'react'

// Components
import Layout from '../../components/Layout'
import {
  Hero,
  Vantagens,
  Objetivo,
  Caracteristicas,
  HistoricoDeRendimento,
  VolumeNegociado,
  PrincipaisIndicadores,
  ComparativoDividendo,
  Portfolio,
  Imoveis,
  OndeComprar,
  MensagemAosInvestidores,
  Documentacao,
  RelatosGerenciais,
  Newsletter,
  PrincipaisDuvidas,
  Disclaimer,
} from './_sections/_index'

import { IFound } from '../../types/types'

import Sumary from '../../components/Sumary'

import SEO from '../../components/Seo'
import convertToSlug from '../../helpers/convertToSlug'
import { objectHaveKeys } from '../../hooks/utility'
import AcessoAInformacao from '../../pages/_sections/_acesso-a-informacao'
import { IFundos, IHeaderFoundersList, IPageContext, ISumaryData } from './types'

import disclaimerData from '../../assets/data/disclaimer/data.json'

const Fundos: React.FC<IFundos> = ({ pageContext }: IFundos) => {
  const { primeiroNome, segundoNome, fundo, fundos, charts }: IPageContext = pageContext
  const [ headerFoundsList, setHeaderFoundsList ] = React.useState<IHeaderFoundersList | undefined>()
  const [ sumaryData, setSumaryData ] = React.useState<ISumaryData[]>([])
  const {
    nome,
    descricao,
    advantages,
    about,
    portfolio,
    realty,
    messageToInvestors,
    documentacao,
    caracteristicasGerais,
    faq,
    managementReports,
    details,
    purchaseOptions,
    backgroundImage,
  }: IFound = fundo

  React.useEffect(() => {
    const createHeaderFoundsList = fundos.reduce((acc: { title: string; anchor: string }[], current: IFound) => {
      acc.push({
        title: current.nome,
        anchor: `/${convertToSlug(`${primeiroNome} ${segundoNome}`, current.nome)}`,
      })

      return acc
    }, [])

    const headerFoundsListCreated = {
      title: `${primeiroNome} ${segundoNome}`,
      anchor: createHeaderFoundsList,
    }

    setHeaderFoundsList(headerFoundsListCreated)
  }, [ ])

  const addToSumaryData = ({ name, anchor }: { name: string; anchor: string }): boolean => {
    setSumaryData((oldArray: ISumaryData[]) => [ ...oldArray, { name, anchor } ])

    return true
  }

  React.useEffect(() => {
    objectHaveKeys(about) && about && addToSumaryData({ name: 'Objetivo', anchor: 'objetivo' })
    objectHaveKeys(charts) && objectHaveKeys(charts.incomeHistory) && addToSumaryData({
      name: 'Hist. de Rendimento',
      anchor: 'historico-de-rendimentos',
    })

    objectHaveKeys(charts) && objectHaveKeys(charts.negotiatedVolume) && addToSumaryData({
      name: 'Vol. Negociado',
      anchor: 'volume-negociado',
    })

    objectHaveKeys(charts) && objectHaveKeys(charts.mainIndicators) && addToSumaryData({
      name: 'Princ. Indicadores',
      anchor: 'principais-indicadores',
    })

    objectHaveKeys(charts) && objectHaveKeys(charts.yieldComparative) && addToSumaryData({
      name: 'Comparativo',
      anchor: 'comparativo-dividendo',
    })

    objectHaveKeys(portfolio) && portfolio && addToSumaryData({
      name: 'Portfólio',
      anchor: 'portifolio',
    })

    purchaseOptions && addToSumaryData({
      name: 'Onde Comprar?',
      anchor: 'onde-comprar',
    })

    messageToInvestors && addToSumaryData({
      name: 'Mensagem',
      anchor: 'mensagem',
    })

    !!documentacao?.length && addToSumaryData({
      name: 'Documentação/Relatórios',
      anchor: 'documentacao',
    })

    faq?.questions && (faq.questions.length !== 0) && addToSumaryData({
      name: 'Dúvidas',
      anchor: 'duvidas',
    })
  }, [ ])

  return (
    <Layout headerFoundsList={headerFoundsList}>
      <SEO title={nome} />
      <Sumary data={sumaryData} />
      <Hero
        data={{ description: descricao, name: segundoNome, nomeFundo: nome }}
        backgroundImage={backgroundImage}
        details={details}
      />
      {!!advantages?.length && <Vantagens data={advantages} />}
      {objectHaveKeys(about) && about && <Objetivo anchor='objetivo' data={{ body: about.body, videoUrl: about.videoUrl }} />}
      {objectHaveKeys(caracteristicasGerais) && caracteristicasGerais && <Caracteristicas data={ caracteristicasGerais } />}
      {objectHaveKeys(charts) && objectHaveKeys(charts.incomeHistory) && <HistoricoDeRendimento anchor='historico-de-rendimentos' chartData={charts.incomeHistory} />}
      {objectHaveKeys(charts) && objectHaveKeys(charts.negotiatedVolume) && <VolumeNegociado anchor='volume-negociado' chartData={charts.negotiatedVolume} />}
      {objectHaveKeys(charts) && objectHaveKeys(charts.mainIndicators) && <PrincipaisIndicadores anchor='principais-indicadores' chartData={charts.mainIndicators} />}
      {objectHaveKeys(charts) && objectHaveKeys(charts.yieldComparative) && <ComparativoDividendo anchor='comparativo-dividendo' chartData={charts.yieldComparative} />}
      {objectHaveKeys(portfolio) && portfolio && <Portfolio anchor='portifolio' data={portfolio} />}
      {objectHaveKeys(realty) && realty && (realty.items.length !== 0) && <Imoveis anchor='imoveis' data={realty} />}
      {purchaseOptions && <OndeComprar anchor='onde-comprar' data={purchaseOptions} />}
      {messageToInvestors && <MensagemAosInvestidores anchor='mensagem' data={{ messageToInvestors: messageToInvestors }} />}
      {!!documentacao?.length && <Documentacao anchor='documentacao' data={ documentacao } />}
      {!!managementReports?.length && <RelatosGerenciais data={managementReports} />}
      <Newsletter />
      {faq?.questions && (faq.questions.length !== 0) && <PrincipaisDuvidas anchor='duvidas' faq={faq} />}
      <AcessoAInformacao disclaimer={disclaimerData.fundosListados} />
    </Layout>
  )
}

export default Fundos
