/* eslint-disable max-len */
import React, { useState } from 'react'

import * as S from './style'

interface IFAQStructure {
  question: string;
  answer: string;
}

export type ShowAnswerStatusType = 'open' | 'close' | 'inicial'

interface IFAQBlock {
  faq: IFAQStructure;
}

const FAQBlock = ({ faq }: IFAQBlock) => {
  const [ showAnswer, setShowAnswer ] = useState<boolean>(false)

  const handleClick = () => {
    setShowAnswer(!showAnswer)
  }
  return (
    <div>
      <S.QuestionBlock className='d-flex align-items-center justify-content-between pb-4' onClick={handleClick}>
        <h3 className='fs-14 lh-18 fs-md-16 lh-md-20 fw-700 text-gray--750 f-inter mb-0'>{faq.question}</h3>
        <div>
          <S.FAQIcon $active={showAnswer} icon='chevron-up' size='LG' color='#FF9D42' />
        </div>
      </S.QuestionBlock>
      <S.AnswerBlock $active={showAnswer}>
        <p className='fs-12 lh-16 fs-md-14 lh-md-18 text-gray-750 fw-400 f-inter mb-0'>
          {faq.answer}
        </p>
      </S.AnswerBlock>
      <hr />
    </div>
  )
}

export default FAQBlock
