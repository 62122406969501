/* eslint-disable max-len */
import React from 'react'
import IntercoIcons from '../../../../components/IntercoIcons'

import * as S from './style'
import { IDetails } from '../../types'

interface IHeroProps {
  data: {
    name: string;
    description?: string;
    nomeFundo: string;
  };
  backgroundImage?: {
    url: string;
    name: string;
  };
  details?: IDetails;
}

const Hero = ({ data, backgroundImage, details }: IHeroProps) => {
  const { valorAtualCota, ultimoDividendo, patrimonioLiquido, yieldMes }: IDetails = details
  const cardDetailsExist = Boolean(valorAtualCota && ultimoDividendo && patrimonioLiquido && yieldMes)

  const returnValorCota = (value: string) => {
    const rate = parseFloat(value.replace(',', '.').replace('%', ''))
    if (rate < 0) {
      return <span className='tax-variation-negative fs-10 lh-12 d-flex align-items-center'><IntercoIcons icon='arrow-down' size='SM' color='#FD4F47' />{valorAtualCota.rate}</span>
    } else {
      return <span className='tax-variation-positive fs-10 lh-12 d-flex align-items-center'><IntercoIcons icon='arrow-up' size='SM' color='#00AA4F' />{valorAtualCota.rate}</span>
    }
  }

  return (
    <S.HeroSection className=''>
      <S.BackgroundDiv backgroundUrl={backgroundImage} $cardDetailsExist={cardDetailsExist} />
      <div className='container'>
        <div className='row'>
          <div className='header-text-block col-12 mb-5'>
            <span className='fs-12 lh-15 fs-md-16 lh-md-20 text-gray-400 font-inter mb-2 mb-md-4'>{data.name}</span>
            <h1 className='f-sora fs-32 lh-40 fs-md-48 lh-md-60 fs-lg-64 lh-lg-80 text-white fw-600'>
              {data.nomeFundo}
            </h1>
            <h2 className='fs-12 lh-15 fs-md-16 lh-md-20 text-gray-400 fw-400 mb-0'>
              {data.description}
            </h2>
          </div>
          <div className='col col-lg-12 card-position d-flex flex-wrap'>
            {valorAtualCota &&
              <S.Card>
                <span className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-650 d-block'>
                  Valor Atual da cota
                </span>
                <span className='fs-18 lh-22 fs-md-20 lh-md-25 fs-xl-32 lh-xl-40 f-sora fw-600 text-gray-750'>{valorAtualCota.value}</span>
                {returnValorCota(valorAtualCota.rate)}
                <span className='fs-12 lh-15 fs-xl-14 lh-xl-17 fw-400 text-gray-650 d-block'>{valorAtualCota.date}</span>
              </S.Card>
            }
            {ultimoDividendo &&
              <S.Card>
                <span className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-650 d-block'>
                  Último Dividendo
                </span>
                <span className='fs-18 lh-22 fs-md-20 lh-md-25 fs-xl-32 lh-xl-40 f-sora fw-600 text-gray-750'>{ultimoDividendo.value}</span>
                <span className='fs-12 lh-15 fs-xl-14 lh-xl-17 fw-400 text-gray-650 d-block'>{ultimoDividendo.date}</span>
              </S.Card>
            }
            {patrimonioLiquido &&
              <S.Card>
                <span className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-650 d-block'>
                  Patrimônio Líquido
                </span>
                <span className='fs-18 lh-22 fs-md-20 lh-md-25 fs-xl-32 lh-xl-40 f-sora fw-600 text-gray-750'>{patrimonioLiquido.value}</span>
                <span className='fs-12 lh-15 fs-xl-14 lh-xl-17 fw-400 text-gray-650 d-block'>{patrimonioLiquido.date}</span>
              </S.Card>
            }
            {yieldMes &&
              <S.Card>
                <span className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-650 d-block'>
                  Yield Mensal Anualizado
                </span>
                <span className='fs-18 lh-22 fs-md-20 lh-md-25 fs-xl-32 lh-xl-40 f-sora fw-600 text-gray-750'>{yieldMes.value}</span>
                <span className='fs-12 lh-15 fs-xl-14 lh-xl-17 fw-400 text-gray-650 d-block'>{yieldMes.date}</span>
              </S.Card>
            }
          </div>
        </div>
      </div>
    </S.HeroSection>
  )
}

export default Hero
