/* eslint-disable max-len */
import React from 'react'
import DocumentCard from '../../../../components/DocumentCard'

import * as S from './style'

import { IDocumentacaoData } from '../../types'

type RelatosGerenciaisProps = {
  data: {
    nome: string;
    data: string;
    url: string;
  }[];
}

const RelatosGerenciais = ({ data }: RelatosGerenciaisProps) => {
  return (
    <S.SectionWrapper className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Relatórios Gerenciais
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 f-inter text-gray-750 fw-400 m-0'>Acesse aqui os relatórios gerenciais.</p>
          </div>
          <div className='col-12'>
            <div className='row'>
              {
                data.map((item: IDocumentacaoData) => (
                  <DocumentCard key={item.nome} className='col-12 col-md-6 col-lg-4' info={{ date: item.data ?? '', description: 'Fato Relevante', title: item.nome, url: item.url }} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default RelatosGerenciais
