/* eslint-disable max-len */
import React from 'react'

import * as S from './style'
import FAQ from '../../../../components/FAQ'

interface IFaqProps {
  faq: {
    title: string;
    questions: {
      question: string;
      answer: string;
      key: string;
    }[];
    key: string;
  };
  anchor: string;
}

const PrincipaisDuvidas = ({ anchor, faq }: IFaqProps) => {
  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              {faq.title}
            </h2>
          </div>
          <div className='col-12'>
            <FAQ faq={faq.questions} />
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default PrincipaisDuvidas
