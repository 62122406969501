/* eslint-disable max-len */
import React from 'react'

import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts'

import * as S from './style'

interface IGraphicInfo {
  name: string;
  percentage: number;
  key: string;
}

interface IPortfolioPizzaProps extends React.HTMLAttributes<HTMLSelectElement> {
  title: string;
  description: string;
  graphicInfo: IGraphicInfo[];
}

const COLORS = [
  '#039BE5',
  '#C0CA33',
  '#A1887F',
  '#FF7043',
  '#9575CD',
  '#EC407A',
  '#26A69A',
  '#EF5350',
  '#7986CB',
  '#7CB342',
  '#4FC3F7',
  '#DCE775',
  '#BCAAA4',
  '#FFAB91',
  '#B39DDB',
  '#F48FB1',
  '#80CBC4',
  '#EF9A9A',
  '#9FA8DA',
  '#AED581',
]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1
  const x = cx + (radius * 2) * Math.cos(-midAngle * RADIAN)
  const y = cy + (radius * 2) * Math.sin(-midAngle * RADIAN)

  return (
    <>
      <text x={x} y={y - 20} fill='black' textAnchor='middle' dominantBaseline='central' className='fs-12 fs-lg-14 fw-600'>
        {payload.payload.name}
      </text>
      <text x={x} y={y} fill='black' textAnchor='middle' dominantBaseline='central' className='fw-600'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  )
}

const PortfolioPizza = ({ title, description, graphicInfo, ...props }: IPortfolioPizzaProps) => {
  const graphicData = graphicInfo.map((data: IGraphicInfo) => {
    return {
      name: data.name,
      value: data.percentage,
    }
  })

  const renderColorfulLegendText = (value: string, entry: any) => {
    return <span className='text-gray-750'>{value} <span className='fw-700'>{`${(entry.payload.percent * 100).toFixed(0)}%`}</span></span>
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>{`${label} : ${payload[0].value}`}</p>
          <p className='text-gray-750'>{payload.name} <span className='fw-700'>{`${payload.value}%`}</span></p>
        </div>
      )
    }

    return null
  }

  return (
    <S.Card {...props}>
      <h3 className='fs-16 lh-20 fw-600 f-sora text-gray-750 d-block mb-2'>{title}</h3>
      <span className='fs-12 lh-15 f-inter fw-400 text-gray-750 d-block mb-0'>{description}</span>
      <hr />
      <ResponsiveContainer width='100%' height={450}>
        <PieChart width={200} height={400}>
          <Pie dataKey='value' data={graphicData} innerRadius={50} outerRadius={90} cx='50%' fill='#82ca9d'>
            {graphicData.map((entry, index: number) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip formatter={(value: string, name: string, props) => [ `${String(value).replace(/\./g, ',')}%`, `${name}` ]} />
          <Legend iconType='circle' formatter={renderColorfulLegendText} />
        </PieChart>
      </ResponsiveContainer>
    </S.Card>
  )
}

export default PortfolioPizza
