/* eslint-disable max-len */
import React from 'react'

import * as S from './style'

interface IObjetivoProps {
  data: {
    body: string;
    videoUrl: string;
  };
  anchor: string;
}

const Objetivo = ({ anchor, data }: IObjetivoProps) => {
  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className={`col-12 ${data.videoUrl ? 'col-md-6' : 'col-md-12'} mb-5 mb-md-0`}>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Objetivo
            </h2>
            <div className='fs-14 lh-17 fs-lg-16 lh-lg-20 f-inter text-gray-750 fw-400 m-0' dangerouslySetInnerHTML={{ __html: data.body }} />
          </div>
          {data.videoUrl &&
            <div className='col-12 col-md-6 d-md-flex align-items-center'>
              <S.VideoThumb src={data.videoUrl} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen />
            </div>
          }
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Objetivo
