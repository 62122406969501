import styled, { css } from 'styled-components'
import IntercoIcons from '../../IntercoIcons'

interface IActive {
  $active: boolean;
}

export const FAQ = styled.div`
`

export const QuestionBlock = styled.div`
  cursor: pointer;
`

export const AnswerBlock = styled.div<IActive>`
  transition: .4s ease-in-out;
  overflow: hidden;
  opacity: 0;
  height: 0;

  ${(props: IActive) => props.$active && css`
  visibility: visible;
    opacity: 1;
    height: auto;
  `};
`

export const FAQIcon = styled(IntercoIcons)<IActive>`
  width: 24px;
  height: 24px;

  transition: .4s ease-in-out;

  ${(props: IActive) => props.$active && css`
    transform: rotate(180deg);
  `};
`
