/* eslint-disable max-len */
import React, { useState } from 'react'

import * as S from './style'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { IBar, IChart, IChartKeyList, IListedFoundsSections } from '../../types'
import { formatToDayMonthYearDate, getMonthAndYear, haveAlphabeticChar, returnChartKeysList } from '../../../../hooks/utility'
import slugify from 'slugify'

interface IVolumeNegociado extends IListedFoundsSections {
  chartData: {
    name: string;
    bars: IBar[];
    type: string;
  };
}

const VolumeNegociado = ({ anchor, chartData }: IVolumeNegociado) => {
  const [ chart, setChart ] = useState<IChart[] | null>(null)
  const [ chartKeyList, setChartKeyList ] = React.useState<IChartKeyList[]>()

  React.useEffect(() => {
    const chartMap = chartData.bars.map((bar: IBar) => {
      return {
        name: bar.xAxis,
        [chartData.name]: bar.yAxis,
      }
    })

    const filteredArray = chartMap.filter((obj: IChart) => obj.name !== undefined)

    formatToDayMonthYearDate(filteredArray)

    const keysList: IChartKeyList[] = returnChartKeysList(filteredArray)
    setChartKeyList(keysList)

    setChart(filteredArray.slice(-12))
  }, [])

  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Volume Negociado
            </h2>
          </div>
          <div className='col-12'>
            {chart && chartKeyList &&
              <ResponsiveContainer width='100%' height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={chart}
                  margin={{
                    top: 20,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' tickFormatter={(value: string) => haveAlphabeticChar(value) ? getMonthAndYear(value) : value } />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign='top' align='left' iconType='rect' height={60} />
                  {chartKeyList.map((chart: IChartKeyList) => (
                    <Bar key={slugify(String(chart.lineDataKey))} dataKey={chart.lineDataKey} stackId='a' fill='#82ca9d' />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            }
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default VolumeNegociado
