import styled from 'styled-components'
import { gray, white } from '../../../../styles/colors'
import breakpoints from '../../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  background-color: ${white};

  .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
`
