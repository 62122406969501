/* eslint-disable max-len */
import React from 'react'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

import * as S from './style'
import { IChart, IIncomeHistoryChart, IIncomeHistoryData, IListedFoundsSections } from '../../types'
import { formatToDayMonthYearDate, haveAlphabeticChar, removeUndefinedNameFromChartArray } from '../../../../hooks/utility'
import slugify from 'slugify'

interface IHistoricoDeRendimento extends IListedFoundsSections {
  chartData: IIncomeHistoryChart[];
}

const HistoricoDeRendimento = ({ anchor, chartData }: IHistoricoDeRendimento) => {
  const [ chart, setChart ] = React.useState<IChart[] | null>(null)

  const returnChartData = () => {
    const chartReduced = chartData.reduce((accumulator: IChart[], currentValue: IIncomeHistoryChart) => {
      currentValue.data.forEach((data: IIncomeHistoryData) => {
        accumulator.push({
          name: data.xAxis,
        })
      })
      return accumulator
    }, [])

    const uniqueArray = chartReduced.filter((object: IChart, index: number) => {
      return index === chartReduced.findIndex((obj: IChart) => obj.name === object.name)
    })

    const filteredArray = removeUndefinedNameFromChartArray(uniqueArray)

    chartData.forEach((chart: IIncomeHistoryChart) => {
      chart.data.forEach((data: IIncomeHistoryData, indexData: number) => {
        if (filteredArray[indexData] && filteredArray[indexData].name) {
          if (data.xAxis === filteredArray[indexData].name) {
            filteredArray.splice(indexData, 1, {
              ...filteredArray[indexData],
              [chart.name]: data.yAxis && parseFloat(data.yAxis.replace('R$ ', '')),
            })
          }
        }
      })
    })

    formatToDayMonthYearDate(filteredArray)

    return filteredArray.slice(-12)
  }

  React.useEffect(() => {
    setChart(returnChartData())
  }, [])

  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Histórico de rendimento
            </h2>
          </div>
          <div className='col-12'>
            {chart &&
              <ResponsiveContainer width='100%' height={300}>
                <ComposedChart
                  data={chart}
                  margin={{
                    top: 20,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <CartesianGrid stroke='#f5f5f5' />
                  <XAxis dataKey='name' scale='band' />
                  <YAxis />
                  <YAxis yAxisId='right' orientation='right' />
                  <Tooltip />
                  <Legend verticalAlign='top' align='left' iconType='rect' height={70} />
                  {chartData.map((chart: IIncomeHistoryChart) => {
                    if (chart.type === 'bar') {
                      return <Bar key={slugify(chart.name)} dataKey={chart.name} barSize={20} fill='#D9D9D9' />
                    } else if (chart.type === 'line') {
                      return <Line key={slugify(chart.name)} type='monotone' yAxisId='right' dataKey={chart.name} stroke='#ff7300' />
                    }
                  })}
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default HistoricoDeRendimento
