import styled from 'styled-components'
import { gray } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

export const Imoveis = styled.div`

`

export const BannerImoveisShowcase = styled.img`
  width: 100%;
  height: 160px;
  border-radius: 8px;
  object-fit: cover;

  @media (min-width: ${breakpoints.md}) {
    height: 208px;
  }
`

export const CardsWrapper = styled.div`
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.md}) {
    gap: 24px;
  }

`

export const Card = styled.div`
  background-color: ${gray[400]};

  border: 1px solid ${gray[550]};
  border-radius: 8px;

  height: 190px;

  @media (min-width: ${breakpoints.md}) {
    width: 332px;
    height: 94px;
    svg {
      width: 28px;
      height: 28px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 294px;
    height: 94px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 368px;
    height: 102px;
  }

`
