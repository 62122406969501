import styled from 'styled-components'
import { gray } from '../../../styles/colors'
import breakpoints from '../../../styles/breakpoints'

export const Card = styled.section`
  background-color: ${gray[400]};
  border: 1px solid ${gray[550]};
  padding: 16px 8px;


  @media (min-width: ${breakpoints.md}) {
    padding: 16px 24px;
  }

  border-radius: 8px;

  hr {
    border: 1px dotted ${gray[550]};
  }
`
