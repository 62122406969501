import React from 'react'

import IntercoIcons from '../../../../components/IntercoIcons'
import DefaultCarousel from '../../../../components/UI/Carousels/DefaultCarousel'

import * as S from './style'

import { IVantagens } from '../../types'

interface IVantagensProps {
  data: IVantagens[];
}

const Vantagens = ({ data }: IVantagensProps) => {
  return (
    <S.SectionWrapper className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600'>
              Vantagens de investir no LUGG11
            </h2>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 2 }}
              md={{ items: 4 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
            >
              {
                data.map((item: IVantagens) => (
                  <div key={item.title} className='px-1'>
                    <S.Card className='d-flex flex-column p-3'>
                      <div className='mb-3'>
                        <IntercoIcons icon={item.icon} size='MD' color='#FF7A00' />
                      </div>
                      <div>
                        <h3 className='fs-16 lh-20 fs-lg-24 lh-lg-30 f-sora text-gray-750 fw-700 mb-3' dangerouslySetInnerHTML={{ __html: item.title }} />
                        <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 f-inter text-gray-750 fw-400 m-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </S.Card>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Vantagens
