/* eslint-disable max-len */
import React from 'react'
import * as S from './style'
import ImoveisShowcase from '../../../../components/ImoveisShowcase'

type ImoveisProps = {
  data: {
    url: string;
    items: {
      name: string;
      location: string;
      units: string;
      key: string;
    }[];
    key: string;
  };
  anchor: string;
}

const Imoveis = ({ anchor, data }: ImoveisProps) => {
  return (
    <S.SectionWrapper id={anchor} className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mt-4'>
            <ImoveisShowcase data={data} />
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Imoveis
