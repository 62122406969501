/* eslint-disable max-len */
import React from 'react'

import * as S from './style'
import FAQBlock from './FAQBlock'

export interface IFAQStructure {
  question: string;
  answer: string;
}

export type ShowAnswerStatusType = 'open' | 'close' | 'inicial'

export interface IFAQ {
  faq: IFAQStructure[];
}

const FAQ = ({ faq }: IFAQ) => {
  return (
    <S.FAQ>
      {faq.map((faq: IFAQStructure) => (
        <FAQBlock faq={faq} key={faq.question} />
      ))}
    </S.FAQ>
  )
}

export default FAQ
