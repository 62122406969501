/* eslint-disable max-len */
import React from 'react'
import * as S from './style'
import DefaultCarousel from '../UI/Carousels/DefaultCarousel'
import IntercoIcons from '../IntercoIcons'

interface ICard {
  name: string;
  location: string;
  units: string;
  key: string;
}

interface IImoveisShowcaseProps {
  data: {
    url: string;
    items: ICard[];
    key: string;
  };
}

const ImoveisShowcase = ({ data }: IImoveisShowcaseProps) => {
  return (
    <S.Imoveis>
      <h4 className='fs-20 lh-26 fw-600 f-sora text-gray-750 mb-0 mb-3'>Imóveis</h4>
      {data.url && <S.BannerImoveisShowcase src={data.url} alt='' className='my-3' />}
      <div className='d-block d-md-none'>
        <DefaultCarousel
          sm={{ items: 2 }}
          md={{ items: 6 }}
          lg={{ items: 6 }}
          xl={{ items: 6 }}
        >
          {
            data.items.map((item: ICard) => (
              <div key={item.key} className='px-1'>
                <S.Card className='d-flex flex-column p-3'>
                  <div className='mb-3'>
                    <IntercoIcons icon='building' size='MD' color='#6A6C72' />
                  </div>
                  <div>
                    <h4 className='fs-14 lh-17 fs-xl-16 lh-xl-20 f-sora text-gray-750 fw-700 mb-2' dangerouslySetInnerHTML={{ __html: item.name }} />
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 f-inter text-gray-750 fw-400 mb-2' dangerouslySetInnerHTML={{ __html: item.location }} />
                    <p className='fs-12 lh-15 f-inter text-gray-750 fw-400 m-0'>{item.units} unidades</p>
                  </div>
                </S.Card>
              </div>
            ))
          }
        </DefaultCarousel>
      </div>
      <S.CardsWrapper className='d-none d-md-flex'>
        {
          data.items.map((item: ICard) => (
            <div key={item.name}>
              <S.Card className='d-flex p-3'>
                <div className='mr-3'>
                  <IntercoIcons icon='building' size='LG' color='#6A6C72' />
                </div>
                <div>
                  <h4 className='fs-14 lh-17 fs-xl-16 lh-xl-20 f-sora text-gray-750 fw-700 mb-2' dangerouslySetInnerHTML={{ __html: item.name }} />
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 f-inter text-gray-750 fw-400 mb-2' dangerouslySetInnerHTML={{ __html: item.location }} />
                  <p className='fs-12 lh-15 f-inter text-gray-750 fw-400 m-0'>{item.units} unidades</p>
                </div>
              </S.Card>
            </div>
          ))
        }
      </S.CardsWrapper>
    </S.Imoveis>
  )
}

export default ImoveisShowcase
