import styled from 'styled-components'
import { gray } from '../../../../styles/colors'
import breakpoints from '../../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background-color: ${gray[400]};

  .react-multi-carousel-dot {
    button {
      width: 8px !important;
      height: 5px !important;
      border-radius: 16px;
      transition: .3s ease-in-out;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      width: 16px !important;
      height: 5px !important;
      border-radius: 16px;
    }
  }
 `

export const VideoThumb = styled.iframe`
  width: 312px;
  height: 158px;

  @media (min-width: ${breakpoints.md}) {
    width: 336px;
    height: 170px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 456px;
    height: 232px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 560px;
    height: 280px;
  }
`
